import {Card, Descriptions, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {DhDetailDataType} from "../common/DhDetailType";
import ViewFileListClass from "../../common/modal/ViewFileList";
import {FormTitle, getTitle, Veteran} from "../common/IdentityData";
import {unitObj} from "../common/DhDictData";

type DhWarVeteranType = {

    serviceUserFee: DhDetailDataType["data"]["serviceUserFee"]
    showFile?: boolean | true,
    warVeteran?: DhDetailDataType["data"]["warVeteran"]
    illness?: DhDetailDataType["data"]["illness"]
}

const ServiceUserFeeInfo: React.FC<DhWarVeteranType> = (props) => {

    const [showFile, setShowFile] = useState<boolean>();
    const [titleObj, setTitleObj] = useState<FormTitle>(Veteran);


    useEffect(() => {
        setTitleObj(getTitle(props.warVeteran?.serviceIdentity));
    }, [props])

    useEffect(() => {
        if (props.showFile === null || props.showFile === undefined) {
            setShowFile(true)
        } else {
            setShowFile(props.showFile)

        }
    }, [])
    return (
        <>
            {(props.serviceUserFee && props.serviceUserFee.surgeryStart && props.illness) && (
                <Card size="small"
                      title={`费用信息`}
                >
                    <Descriptions>
                        <Descriptions.Item label="援助类型">
                            {props.serviceUserFee.projectTypes}
                        </Descriptions.Item>
                        <Descriptions.Item label="手术时间">
                            {props.serviceUserFee.surgeryStart}
                        </Descriptions.Item>
                    </Descriptions>
                    {props.illness.map((item: any, index: number) => (
                        <Descriptions>
                            <Descriptions.Item label={"援助项目(" + item.projectTypeName + ")"}>
                                {item.typeDetailName}
                            </Descriptions.Item>
                            <Descriptions.Item label={item.typeDetailName +
                                "(" + unitObj[item.projectTypeName] + ")"}>
                                {item.teethNum}
                            </Descriptions.Item>
                        </Descriptions>
                    ))}
                    <Descriptions>
                        <Descriptions.Item label="总金额">
                            {props.serviceUserFee.totalPrice}
                        </Descriptions.Item>
                        <Descriptions.Item label="报销前自付费用">
                            {props.serviceUserFee.selfPayPrice}
                        </Descriptions.Item>
                        <Descriptions.Item label="本项目资助">
                            {props.serviceUserFee.subsidy}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="报销后自付费用">
                            {props.serviceUserFee.afterSelfPayPrice}
                        </Descriptions.Item>
                        <Descriptions.Item label="备注">
                            {props.serviceUserFee.remark}
                        </Descriptions.Item>
                    </Descriptions>
                    {showFile && (
                        <Space size={[8, 16]} wrap align="start">
                            <Card style={{width: '100%'}}>
                                <ViewFileListClass
                                    title="知情同意书"
                                    fileList={props.serviceUserFee.informed}
                                />
                            </Card>
                            <Card style={{width: '100%'}}>
                                <ViewFileListClass
                                    title="手术/救治同意书"
                                    fileList={props.serviceUserFee.consentForms}
                                />
                            </Card>
                            <Card style={{width: '100%'}}>
                                <ViewFileListClass
                                    title="病例"
                                    fileList={props.serviceUserFee.hospitalizationRecords}
                                />
                            </Card>
                            <Card style={{width: '100%'}}>
                                <ViewFileListClass
                                    title="收费凭证"
                                    fileList={props.serviceUserFee.invoices}
                                />
                            </Card>
                            {(props.serviceUserFee.proofIdentity
                                    && props.serviceUserFee.proofIdentity.length > 0)
                                && (
                                    <Card style={{width: '100%'}}>
                                        <ViewFileListClass
                                            title={titleObj.proofOfIdentity}
                                            fileList={props.serviceUserFee.proofIdentity}
                                        />
                                    </Card>
                                )}
                            {(props.serviceUserFee.identityPromise
                                    && props.serviceUserFee.identityPromise.length > 0)
                                && (
                                    <Card style={{width: '100%'}}>
                                        <ViewFileListClass
                                            title="身份承诺书"
                                            fileList={props.serviceUserFee.identityPromise}
                                        />
                                    </Card>
                                )}
                        </Space>
                    )}
                </Card>
            )
            }
        </>
    );
};

export default ServiceUserFeeInfo;
