import {UploadFile} from "antd/es/upload/interface";
import {Dayjs} from "dayjs";


export type BookingVosType = {
    code: string | null,
    bookingDate: string | null,
    bookingTime: number | 0,
    number: number | 0,
    bookingStatus: number | 0
}
export type FileType = {
    type: number | 0,
    fileId: number | 0,
    name: string | '',
    url: string | null
}
export type DhDetailDataType = {
    id: number | null,
    data: {
        tasksDetail?: {
            visitStatus?: number | 0,
            serviceId?: number | 0,
            serviceStatus?: number | 0,
            serviceName: string | null,
            servicePhone: string | null,
            taskType?: number | 0,
        },
        userDetail?: {
            name: string | null,
            age: number | 0,
            gender: number | 0,
            phone: string | null,
            idNumber: string | null,
            hospitalId: number | null,
            hospitalName: string | null,
            bookingCount: number | null,
            bookingDate: string | null,
            registerType: number | 0,
            dhStatus: number | 0;
            bookingId: number | 0,
            serviceUser: null | 0,
            voucher: UploadFile[],
            releaseTime: string | null,
            dateBirth: string | null,
            toothNum: number | 0
        },
        addressDetail?: {
            provinceId: number | 0,
            cityId: number | 0,
            districtId: number | 0,
            locationAddr: string | '',
            areaStr: string | '',
            address: string | null,
            lat: number | null,
            lng: number | null,
        },
        account?: {
            name: string | null,
            phone: string | null,
            backCard: string | null,
            accountBank: string | null,
            idNumber: string | null,
            paymentMethod: string | '',
            paymentMethodStr: string | '',
            relationship: string | null,
            relationshipStr: string | null,
            applicantShow: string | undefined,
            payeeShow: string | undefined,
            signatureShow: string | undefined,
            accountApplicant: UploadFile[],
            accountPayee: UploadFile[],
            accountSignature: UploadFile[],
        },
        warVeteran?: {
            warVeteran: string | '',
            name: string | null,
            idNumber: string | null,
            phone: string | null,
            veteranRelations: string | null,
            warVeteranType: String | null,
            veteranRelationsStr: string | null,
            serviceIdentity: string | '',
            serviceIdentityStr: string | null
        },
        report?: {
            surgery: string | null
            noSurgeryReason: string | null
            report: UploadFile[],
            surgeryStr: null,
            noSurgeryReasonStr: null,
        },
        serviceUserFee?: {
            surgeryStart: string | null,
            surgeryEnd: string | null,
            teethNum: number | null,
            totalPrice: number | null,
            selfPayPrice: number | null,
            subsidy: number | null,
            afterSelfPayPrice: number | null,
            remark: string | null,
            teethType: number | null,
            teethPrice: number | null,
            diagnosticReports: UploadFile[],
            consentForms: UploadFile[],
            hospitalizationRecords: UploadFile[],
            proofIdentity: UploadFile[],
            identityPromise: UploadFile[],
            invoices: UploadFile[],
            informed: UploadFile[],
            teethName: string,
            // projectType: string | "",
            // projectName: string | null,
            // projectTypeId: number | undefined
            // typeDetailId: number | undefined
            projectTypes: string | "",
            projectList: [{ project: string, num: number }]
        },
        subsidyInfo?: {
            totalSubsidy: number | 0,
            minTotalPrice: number | 0,
            msg: string | null,
            subVos: [{
                subsidy: number | 0,
                typeName: string | "",
                projectName: string | '',
                activityPrice: number | 0,
                singleSubsidy: number | 0
            }]
        },
        files?: FileType[],
        bookingVos?: BookingVosType[],
        illness?: [{
            projectTypeName: string,
            typeDetailName: string,
            teethNum: number,
            projectTypeId: number,
            typeDetailId: number
        }]
    }
}

export type EditType = {

    name?: string | null,
    age?: number | 0,
    gender?: number | 0,
    phone?: string | null,
    idNumber?: string | null,
    provinceId?: number | 0,
    cityId?: number | 0,
    districtId?: number | 0,
    areaStr?: string | null,
    addressDetail?: string | null,
    locationAddr?: string | null,
    lng?: number | null,
    lat?: number | null,

    serviceIdentity?: string | null,
    warVeteran?: string | undefined,
    warVeteranName?: string | null,
    warVeteranIdNumber?: string | null,
    warVeteranPhone?: string | null,
    warVeteranRelations?: string | null,
    paymentMethod?: string | null,
    accountName?: string | null,
    accountBank?: string | null,
    accountBackCard?: string | null,
    accountIdNumber?: string | null,
    accountPhone?: string | null,
    accountRelationshipId?: string | null,
    accountApplicant?: UploadFile[],
    accountPayee?: UploadFile[],
    accountSignature?: UploadFile[],

    rSurgery?: string | null,
    rNoSurgeryReason?: string | null,
    rDiagnosticReports?: UploadFile[],
    //费用信息
    surgeryStart?: Dayjs | undefined,
    surgeryEnd?: Dayjs | undefined,
    teethNum?: number | null,
    totalPrice?: number | null,
    remark?: string | null
    diagnosticReports?: UploadFile[],
    consentForms?: UploadFile[],
    hospitalizationRecords?: UploadFile[],
    invoices?: UploadFile[],
    proofIdentity?: UploadFile[],
    identityPromise?: UploadFile[],
    informed?: UploadFile[],
    teethType?: number | null,
    toothNum?: number | null,
    projectTypeId?: any[],
    typeDetailId?: number | undefined
    projectTypes?: ProjectType[]

}


function createDhDetailData(): DhDetailDataType {
    return {
        id: null,
        data: {
            tasksDetail: {
                visitStatus: 0,
                serviceId: 0,
                serviceStatus: 0,
                serviceName: null,
                servicePhone: null,
                taskType: 0
            },
            userDetail: {
                name: null,
                age: 0,
                gender: 0,
                phone: null,
                idNumber: null,
                hospitalId: null,
                hospitalName: null,
                bookingCount: null,
                bookingDate: null,
                registerType: 0,
                dhStatus: 0,
                bookingId: 0,
                serviceUser: null,
                voucher: [],
                releaseTime: null,
                dateBirth: null,
                toothNum: 0
            },
            addressDetail: {
                provinceId: 0,
                cityId: 0,
                districtId: 0,
                locationAddr: '',
                areaStr: '',
                address: null,
                lat: null,
                lng: null,
            },
            account: {
                name: null,
                phone: null,
                backCard: null,
                accountBank: null,
                idNumber: null,
                paymentMethod: '',
                relationship: null,
                applicantShow: undefined,
                payeeShow: undefined,
                signatureShow: undefined,
                accountApplicant: [],
                accountPayee: [],
                accountSignature: [],
                paymentMethodStr: '',
                relationshipStr: ''
            },
            warVeteran: {
                warVeteran: '',
                name: null,
                idNumber: null,
                phone: null,
                veteranRelations: null,
                warVeteranType: null,
                veteranRelationsStr: null,
                serviceIdentity: '',
                serviceIdentityStr: null
            },
            report: {
                surgery: null,
                noSurgeryReason: null,
                surgeryStr: null,
                noSurgeryReasonStr: null,
                report: [],
            },
            files: [],
            bookingVos: [],
        }
    };
}

export const defaultDhDetailData = createDhDetailData();
// export const editTypeDate: EditType = {
//
//     name: null,
//     age: 0,
//     gender: 0,
//     phone: null,
//     idNumber: null,
//     provinceId: 0,
//     cityId: 0,
//     districtId: 0,
//     areaStr: null,
//     addressDetail: null,
//     locationAddr: null,
//     lng: null,
//     lat: null,
//
//     warVeteran: undefined,
//     warVeteranName: null,
//     warVeteranIdNumber: null,
//     warVeteranPhone: null,
//     warVeteranRelations: null,
//     paymentMethod: null,
//     accountName: null,
//     accountBank: null,
//     accountBackCard: null,
//     accountIdNumber: null,
//     accountPhone: null,
//     accountRelationshipId: null,
//
//     rSurgery: null,
//     rNoSurgeryReason: null,
//     rDiagnosticReports: [],
// }


export type FilePushType = UploadFile & {
    fileId: number;
    id: number
}

export type ProjectType = {
    value: any, label: any, unit: string, selectOp: [],
    fieldName: { project: string, num: string }
}