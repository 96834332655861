import React, {Component} from 'react';
import {Button, Card, Form, FormInstance, message, Modal, Space, Spin,} from 'antd';
import App from '../../../App';
import Fetch from '../../common/FetchIt';
import API from '../../common/api';
import common from '../../common/common';
import BreadcrumbInfo from "../../common/breadcrumb/BreadcrumbInfo";
import {defaultDhDetailData, DhDetailDataType, EditType} from "../common/DhDetailType";
import {decryptData} from "../../common/crypto";
import styles from "../../cooperate/hospitalauditv2/ServiceUserV2.module.css";
import {defDictData, DictObjectType, getDictData} from "../common/DhDictData";
import DhWarVeteranInfo from "../patientDetail/DhWarVeteranInfo";
import DiagnosisReportInfo from "../patientDetail/DiagnosisReportInfo";
import EditServiceUserFee from "../editPatient/EditServiceUserFee";
import {
    buildAccount,
    buildAccountFile,
    buildAddrByFee,
    buildBasicInfoByFee,
    buildIllness,
    buildServiceUserFee
} from "../common/edit/DhSubmitDataConfig";
import {buildBase, buildEditAccount, dhShowFile} from "../common/edit/DhBackfillDataConfig";
import EditAccount from "../editPatient/EditAccount";
import {getKey} from "../../common/breadcrumb/BreadcrumbUtil";
import EditBasicInfoByFee from "../editPatient/EditBasicInfoByFee";


type ReportType = DictObjectType & {

    dhOperation: string | "",
    importLoading: boolean | undefined,
    addressSelect: []
}

const {confirm} = Modal;

class DhServiceUserFeeClass extends Component<{}, DhDetailDataType & ReportType> {


    formRef = React.createRef<FormInstance>();
    state: DhDetailDataType & ReportType = {
        id: decryptData(common.getQuery('id')),
        data: defaultDhDetailData.data,
        dictInfo: defDictData,
        dhOperation: "",
        importLoading: true,
        addressSelect: []
    };

    componentDidMount() {

        getDictData().then((data) => {
            this.getData();
            this.setState({
                dictInfo: data
            })
        })
    };

    getData() {

        const {id} = this.state;
        let url = `${API.dentistry.dh_service_user_booking.do_get_patient_detail}?id=${id}`;
        Fetch.get(url).then((data) => {
            dhShowFile(data).then(() => {
                let formValue: EditType = {};
                this.setState({
                    data: data,
                    importLoading: false
                }, () => {
                    buildEditAccount(formValue, data)
                    buildBase(formValue, data)
                    this.formRef.current?.setFieldsValue({
                        ...formValue
                    });
                });
            })
        });
    }

    cancel = () => {
        window.history.go(-1);
    }
    back = () => {
        // @ts-ignore
        window.history.back(-1)
    }

    getLat = (values: any) => {
        const {addressSelect} = this.state;
        const {locationAddr} = values;
        //说明修改地址了
        if (addressSelect && addressSelect.length > 0) {
            let lat = 0,
                lng = 0;
            const address: any = addressSelect.find((item: {
                value: string | ''
            }) => {
                return item.value === locationAddr;
            });
            if (address && address.location) {
                lat = address.location.lat;
                lng = address.location.lng;
            } else {
                message.error('您输入的地址不正确，请通过下拉列表选择输入');
                return false;
            }
            return {
                lng: lng,
                lat: lat,
            };
        }
    }

    submit = (values: any) => {
        console.log(values)
        let that = this;
        const {data, addressSelect} = this.state;
        confirm({
            title: `是否提交数据`,
            onOk() {
                let target: any = {};
                const point = that.getLat(values);
                // if (!point) {
                //     return
                // }
                buildServiceUserFee(values, target)
                buildAccount(values, target);
                buildAccountFile(values, target);
                buildBasicInfoByFee(values, target);
                buildAddrByFee(values, point, target);
                buildIllness(values, target);
                target.serviceUserFee.serviceId = data.userDetail?.serviceUser
                that.create(target);
            }
        });
    }

    create = (values: any) => {

        Fetch.postJSON(API.dentistry.dh_service_user_fee.do_create_fee, values).then(() => {
            message.success('提交成功');
            window.history.go(-1);
        });
    };

    setAddressSelect = (select: any) => {
        this.setState({
            addressSelect: select
        })
    }


    render() {
        const {data, dictInfo} = this.state;
        return (
            <div>
                <BreadcrumbInfo/>
                <div
                    className="site-layout-background"
                    style={{padding: 24, minHeight: 720}}
                >
                    <Spin tip={'加载中'} spinning={this.state.importLoading}>
                        {!this.state.importLoading && (
                            <Form
                                onFinish={this.submit}
                                ref={this.formRef}
                            >
                                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                    <EditBasicInfoByFee key={"EditBasicInfo"} form={this.formRef}
                                                        setAddressSelect={this.setAddressSelect}
                                                        dictInfo={dictInfo}
                                                        resultData={data}
                                    />
                                    <DhWarVeteranInfo warVeteran={data.warVeteran}/>
                                    <EditAccount key={"EditAccount"} form={this.formRef} dictInfo={dictInfo}
                                                 fileEdit={true} initDate={data} required={true}/>
                                    <DiagnosisReportInfo report={data.report}/>
                                    <EditServiceUserFee form={this.formRef} userDetail={data.userDetail}
                                                        warVeteran={data.warVeteran}
                                                        dictInfo={dictInfo}
                                                        serviceUserFee={data.serviceUserFee}
                                                        report={data.report}
                                    />
                                    <Card>
                                        <Space>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                size="large"
                                                className={styles.postSave}
                                            >
                                                保存
                                            </Button>
                                            <Button
                                                size='large'
                                                className={styles.backBtn}
                                                style={{marginRight: '15px'}}
                                                onClick={this.cancel}
                                            >
                                                取消
                                            </Button>
                                        </Space>
                                    </Card>
                                </Space>
                            </Form>
                        )}
                    </Spin>
                </div>
            </div>
        );
    }
}

export default function DhServiceUserFee() {

    const pageKey = getKey();
    return (
        <App selectedKeys={[pageKey.selectedKeys]}
             openKeys={pageKey.openKeys}>
            <DhServiceUserFeeClass/>
        </App>
    );
}
