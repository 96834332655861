import React, {useEffect, useState} from 'react';
import {Table} from 'antd';
import API from "../../common/api";
import Fetch from "../../common/FetchIt";
import {ColumnsType} from 'antd/es/table';
import {dhStatusColorObj, dhStatusObj} from "../common/DhDictData";
import styles from "./Record.module.css"

type Record = {
    serviceId?: number | null,
    bookingId?: number | null
}
type User = {};
const DhRecordOperationsDetail: React.FC<Record> = (props) => {

    const [recordData, setRecordData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(
        function () {
            if (!props.serviceId) {
                return
            }
            let url = `${API.dentistry.service_user.find_record}?serviceId=` + props.serviceId;
            Fetch.get(url).then((data) => {
                setRecordData(data)
                setLoading(false)
            });
        },
        [props.serviceId]
    );
    useEffect(
        function () {
            if (!props.bookingId) {
                return
            }
            let url = `${API.dentistry.dh_service_user_booking.find_record}?bookingId=` + props.bookingId;
            Fetch.get(url).then((data) => {
                setRecordData(data)
                setLoading(false)
            });
        },
        [props.bookingId]
    );
    const columns: ColumnsType<User> = [
        {
            title: '时间',
            dataIndex: 'createdDate',
            align: `center`,
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: `center`,
            render(value: any) {
                return <span style={{
                    wordBreak: 'break-all',
                    color: dhStatusColorObj[value]
                }}
                >{dhStatusObj[value]}</span>;
            },
        },
        {
            title: '描述',
            dataIndex: 'msg',
            align: `center`,
        }
    ];
    return (
        <>
            <Table
                className={styles.popover}
                rowKey="createdDate"
                dataSource={recordData}
                columns={columns}
                bordered
                loading={loading}
                pagination={{position: [], pageSize: 100}}
                scroll={{y: 430}}
            />
        </>
    );
};


export default DhRecordOperationsDetail;
