import React, {useEffect, useState} from 'react';
import {Card, Col, DatePicker, Input, InputNumber, message, Row, Select, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';
import styles from "../../cooperate/hospitalauditv2/ServiceUserV2.module.css";
import {EditBaseType} from "../common/edit/DhEditAndDetailPageConfig";
import TextArea from "antd/lib/input/TextArea";
import {DhDetailDataType, ProjectType} from "../common/DhDetailType";
import {FormTitle, getTitle, showProofOfIdentity} from "../common/IdentityData";
import {fieldName, unitObj} from "../common/DhDictData";
import FileUploader from "../../common/uploader";
import {UploadOutlined} from "@ant-design/icons";

type EditFreeClinicsReportType = EditBaseType & {

    userDetail: DhDetailDataType["data"]['userDetail'],
    warVeteran: DhDetailDataType["data"]['warVeteran'],
    serviceUserFee: DhDetailDataType["data"]['serviceUserFee'],
    report: DhDetailDataType["data"]['report'],
};


export default function EditServiceUserFee(props: EditFreeClinicsReportType) {

    const [dhOperation, setDhOperation] = useState<string>('1');
    const [titleObj, setTitleObj] = useState<FormTitle>();
    const [projectTypes, setProjectTypes] = useState<any>();

    const [selectLabels, setSelectLabels] = useState<ProjectType[] | []>([]);

    useEffect(() => {
        projectTypeSelect();
    }, [])

    useEffect(() => {
        if (!projectTypes) {
            return;
        }
        const projectTypeIds = props.form.current?.getFieldValue("projectTypeId");
        if (projectTypeIds && props.serviceUserFee) {
            //修改
            let projectTypeTemps: any[] = [];
            projectTypeIds.forEach((typeItem: number) => {
                const filterElement = projectTypes.filter((item: any) => item.value === typeItem)[0];
                projectTypeTemps.push(filterElement)
            })
            updateSelectLabel(projectTypeTemps);
        } else {
            //新增
            const projectType = projectTypes[0];
            projectTypeDetailSelect(projectType.value).then((data) => {
                const labels = [{
                    value: projectType.value,
                    label: projectType.label,
                    unit: "(" + unitObj[projectType.label] + ")",
                    selectOp: data,
                    fieldName: fieldName[projectType.label]
                }]
                setSelectLabels(labels)
                props.form.current?.setFieldsValue({
                    projectTypeId: [projectType.value],
                    projectTypes: labels
                });
            })
        }
    }, [projectTypes])

    useEffect(() => {

        setTitleObj(getTitle(props.warVeteran?.serviceIdentity));
    }, [props])


    const projectTypeSelect = () => {

        Fetch.get(`${API.dentistry.dh_project_type.find_by_hospital_id}`).then((data) => {
            setProjectTypes(data)
        });
    }

    const projectTypeDetailSelect = async (typeId: any) => {
        try {
            return await Fetch.get(`${API.dentistry.dh_project_type_detail.find_by_type_id}?typeId=${typeId}`);
        } catch (error) {
            console.error("Error fetching project type details:", error);
            throw error;
        }
    };

    const projectTypeIdChange = (e: any, item: any) => {

        updateSelectLabel(item);
    };

    const updateSelectLabel = (items: any) => {

        if (items.length === 0) {
            message.warning("至少选择一个类型！");
            return;
        } else {
            let promises = items.map((obj: any) => {
                const selectLabelOne = selectLabels.filter(option => option.value === obj.value)[0];
                if (selectLabelOne && selectLabelOne.selectOp && selectLabelOne.selectOp.length > 0) {
                    return Promise.resolve({
                        ...obj,
                        unit: selectLabelOne.unit,
                        selectOp: selectLabelOne.selectOp,
                        fieldName: selectLabelOne.fieldName
                    });
                } else {
                    return projectTypeDetailSelect(obj.value).then((data) => ({
                        ...obj,
                        unit: "(" + unitObj[obj.label] + ")",
                        selectOp: data,
                        fieldName: fieldName[obj.label]
                    }));
                }
            });
            Promise.all(promises).then((labels: any) => {
                setSelectLabels(labels);
                props.form.current?.setFieldsValue({projectTypes: labels});
            });
        }
    }

    return (
        <>
            <Card size="small" className={styles.mycard}
                  title={`费用信息`}>

                <Row gutter={20}>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="projectTypeId"
                            label="请选择类型"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Select
                                mode="multiple"
                                style={{width: '100%'}}
                                options={projectTypes}
                                placeholder="请选择类型"
                                onChange={projectTypeIdChange}
                            >
                            </Select>
                        </FormItem>
                        <FormItem
                            className={styles.mylabel}
                            name="projectTypes"
                            label="请选择类型"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                            hidden={true}
                        >
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="surgeryStart"
                            label={"治疗时间"}
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <DatePicker style={{width: '100%'}}
                                        placeholder={"治疗开始时间"}/>
                        </FormItem>
                    </Col>
                </Row>
                {selectLabels.map((item: any, index: number) => (
                    <Row gutter={20} key={index}>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name={item.fieldName.project}
                            label="援助项目"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <Select
                                placeholder="援助项目"
                                style={{width: '100%'}}
                                options={item.selectOp}
                            >
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={10}>
                            <FormItem
                                className={styles.mylabel}
                                name={item.fieldName.num}
                                label={item.label + item.unit}
                                labelCol={{span: 5}}
                                wrapperCol={{span: 11}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <InputNumber
                                    precision={0}
                                    style={{width: '100%'}}
                                    max={50}
                                    min={0}
                                    // onChange={this.computeSelfPayPrice}
                                    placeholder={item.label + item.unit}
                                />
                            </FormItem>
                    </Col>
                </Row>
                ))}

                <Row gutter={20}>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="totalPrice"
                            label="总金额"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <InputNumber
                                // precision={2}
                                style={{width: '100%'}}
                                max={999999}
                                min={0}
                                placeholder="总金额"
                            />
                        </FormItem>
                    </Col>
                    <Col span={10}>
                        <FormItem
                            className={styles.mylabel}
                            name="remark"
                            label="备注"
                            labelCol={{span: 5}}
                            wrapperCol={{span: 11}}
                            rules={[{required: false, message: '必填'}]}
                        >
                            <TextArea rows={4} placeholder="备注" maxLength={500}/>
                        </FormItem>
                    </Col>
                </Row>
                {/*align="start"*/}
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="informed"
                            valuePropName="fileList"
                            label="知情同意书"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                            />
                        </FormItem>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="consentForms"
                            valuePropName="fileList"
                            label="手术/救治同意书"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                            />
                        </FormItem>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="hospitalizationRecords"
                            valuePropName="fileList"
                            label="病例"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                            />
                        </FormItem>
                    </Card>
                    <Card style={{width: '100%'}}>
                        <FormItem
                            className={styles.mylabel}
                            name="invoices"
                            valuePropName="fileList"
                            label="收费凭证"
                            labelCol={{span: 2}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '必填'}]}
                        >
                            <FileUploader
                                multiple
                                listType="picture"
                                className="upload-list-inline"
                                accept='*'
                                checkSize={false}
                                noChekckSizeMal={true}
                                icon={<UploadOutlined/>}
                                rules={[{required: true, message: '必填'}]}
                            />
                        </FormItem>
                    </Card>
                    {showProofOfIdentity(props.warVeteran?.serviceIdentity) && (
                        <Card style={{width: '100%'}}>
                            <FormItem
                                className={styles.mylabel}
                                name="proofIdentity"
                                valuePropName="fileList"
                                label={titleObj?.proofOfIdentity}
                                labelCol={{span: 2}}
                                wrapperCol={{span: 17}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <FileUploader
                                    multiple
                                    listType="picture"
                                    className="upload-list-inline"
                                    accept='*'
                                    checkSize={false}
                                    noChekckSizeMal={true}
                                    icon={<UploadOutlined/>}
                                    rules={[{required: true, message: '必填'}]}
                                />
                            </FormItem>
                        </Card>
                    )}
                    {(props.warVeteran?.warVeteran === '2' &&
                        showProofOfIdentity(props.warVeteran?.serviceIdentity)) && (
                        <Card style={{width: '100%'}}>
                            <FormItem
                                className={styles.mylabel}
                                name="identityPromise"
                                valuePropName="fileList"
                                label="身份承诺书"
                                labelCol={{span: 2}}
                                wrapperCol={{span: 17}}
                                rules={[{required: true, message: '必填'}]}
                            >
                                <FileUploader
                                    multiple
                                    listType="picture"
                                    className="upload-list-inline"
                                    accept='*'
                                    checkSize={false}
                                    noChekckSizeMal={true}
                                    icon={<UploadOutlined/>}
                                    rules={[{required: true, message: '必填'}]}
                                />
                            </FormItem>
                        </Card>
                    )}
                </Space>
            </Card>
        </>
    );
}
