import React, {useEffect, useState} from 'react';
import {Button, Card, DatePicker, Descriptions, Form, FormInstance, message, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';
import FileUploader from "../../common/uploader";
import {UploadOutlined} from '@ant-design/icons';
import type {RangePickerProps} from 'antd/es/date-picker';
import dayjs from 'dayjs';
import {DhDetailDataType} from "../common/DhDetailType";
import ServiceUserFeeInfo from "../patientDetail/ServiceUserFeeInfo";
import {buildProjectTypes} from "../common/edit/DhBackfillDataConfig";

/**
 * 报销弹窗
 * @param props
 * @constructor
 */
type DhServiceFeeType = {
    serviceUserFee: DhDetailDataType["data"]["serviceUserFee"]
    illness?: DhDetailDataType["data"]["illness"]
}

export default function DhServiceUserReimbursed(props: any) {

    const reason = props.importResult;
    const formRef = React.createRef<FormInstance>();
    const [feeData, setFeeData] = useState<DhServiceFeeType>();
    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {
        buildFile(values)
        values.serviceId = reason;
        values.releaseTime = values.releaseTime.format('YYYY-MM-DD');
        Fetch.postJSON(API.dentistry.service_user_status.update_complete, values).then(() => {
            message.success('提交成功');
            ok();
        });
    }

    const buildFile = (values: any) => {

        const files: any[] = [];
        addFiles(files, values.voucher, 10);
        values.files = files;
        delete values.voucher;
    }

    const addFiles = (files: any[], data: any, type: number) => {
        data.forEach((item: {
            id: any;
        }) => {
            files.push({
                type: type,
                fileId: item.id
            })
        })
    }
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && (current < dayjs().startOf('month') || current >= dayjs().endOf('month'))
    };
    useEffect(() => {
        Fetch.get(`${API.dentistry.dh_service_user_fee.finance_complete}?serviceId=${reason}`).then((data) => {

            buildProjectTypes(data.serviceUserFee, data.illness)
            setFeeData(data)
        });
    }, []);

    return <>
        {feeData != null ? (
            <>
                <Form onFinish={submit}
                      ref={formRef}>
                    <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                        {/*TODO 异常*/}
                        <ServiceUserFeeInfo serviceUserFee={feeData.serviceUserFee} showFile={false}
                                            illness={feeData.illness}/>

                        <Card size="small"
                              title={`上传凭证`}>
                            <Descriptions>
                                <Descriptions.Item>
                                    <FormItem
                                        name="releaseTime"
                                        label="补贴款发放时间"
                                        // labelCol={{span: 0}}
                                        wrapperCol={{span: 17}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        <DatePicker
                                            disabledDate={disabledDate}
                                            style={{width: '100%'}}/>
                                    </FormItem>
                                </Descriptions.Item>
                                <Descriptions.Item>
                                    <FormItem
                                        name="voucher"
                                        valuePropName="fileList"
                                        label="上传凭证"
                                        // labelCol={{span: 0}}
                                        wrapperCol={{span: 17}}
                                        rules={[{required: true, message: '必填'}]}
                                    >
                                        <FileUploader
                                            multiple
                                            listType="picture"
                                            className="upload-list-inline"
                                            accept='*'
                                            checkSize={false}
                                            noChekckSizeMal={true}
                                            icon={<UploadOutlined/>}
                                        />
                                    </FormItem>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                            >
                                保存
                            </Button>
                            <Button
                                size='large'
                                onClick={cancel}
                            >
                                取消
                            </Button>
                        </Space>
                    </Space>
                </Form>
            </>
        ) : ('')}
    </>;
}
