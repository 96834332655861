import {Card, Descriptions, Space} from 'antd';
import React from 'react';
import {DhDetailDataType} from "../common/DhDetailType";

type DhWarVeteranType = {

    subsidyInfo: DhDetailDataType["data"]["subsidyInfo"]
}

const DhSubsidyInfoInfo: React.FC<DhWarVeteranType> = (props) => {

    return (
        <>
            {(props.subsidyInfo && props.subsidyInfo.totalSubsidy) && (
                <Card size="small"
                      title={`报销信息`}
                >
                    <Descriptions>
                        <Descriptions.Item label="合计补贴">
                            {props.subsidyInfo.totalSubsidy}
                        </Descriptions.Item>
                        <Descriptions.Item label="预计最少花费">
                            {props.subsidyInfo.minTotalPrice}
                        </Descriptions.Item>
                        {props.subsidyInfo.msg && (
                            <Descriptions.Item label="错误信息">
                                {props.subsidyInfo.msg}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                    <Space direction="horizontal" wrap={true}>
                        {props.subsidyInfo.subVos.map((item: any, index: number) => (
                            <Descriptions bordered={true}>
                                <Descriptions.Item label="援助类型">
                                    {item.typeName}
                                </Descriptions.Item>
                                <Descriptions.Item label="援助项目">
                                    {item.projectName}
                                </Descriptions.Item>
                                <Descriptions.Item label="活动金额">
                                    {item.activityPrice}
                                </Descriptions.Item>
                                <Descriptions.Item label="补贴金额">
                                    {item.singleSubsidy}
                                </Descriptions.Item>
                                <Descriptions.Item label="项目合计补贴金额">
                                    {item.subsidy}
                                </Descriptions.Item>
                            </Descriptions>
                        ))}
                    </Space>

                </Card>
            )
            }
        </>
    );
};

export default DhSubsidyInfoInfo;
