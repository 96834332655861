import React, {useEffect, useState} from 'react';
import {Button, Form, FormInstance, message, Space} from 'antd';
import Fetch from "../../common/FetchIt";
import API from "../../common/api";
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';

type BatchFailedType = {
    serviceId: number | undefined,
    msg: string | undefined;
    importResult: any;
    hide: any;
    reload: any;
    failedBack: any;
}

/**
 * 报销失败弹窗
 * @param props
 * @constructor
 */
export default function ServiceUserReimbursedBatchFailed(props: BatchFailedType) {

    const formRef = React.createRef<FormInstance>();
    const [submitButton, setSubmitButton] = useState(false);
    const cancel = () => {
        props.hide();
    }
    const ok = () => {
        props.hide();
        props.reload();
    }
    const submit = (values: any) => {

        setSubmitButton(true)
        values.serviceUserId = props.serviceId;
        Fetch.postJSON(API.dentistry.service_user_status.update_erp_fail, values)
            .then(() => {
                message.success('提交成功');
                setSubmitButton(false)
                ok();
                props.failedBack(true, props.serviceId);
            })
            .catch(() => {
                cancel();
                props.failedBack(false, props.serviceId);
            });
    }

    useEffect(() => {
        formRef.current?.setFieldValue("msg", props.msg)
    }, []);


    return <>
        <Form onFinish={submit}
              ref={formRef}>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <FormItem
                    name="msg"
                    label="报销失败原因"
                    rules={[{required: true, message: '必填'}]}
                >
                    <TextArea showCount rows={4} placeholder="拒绝原因" maxLength={50}/>
                </FormItem>
                <Space>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={submitButton}
                    >
                        保存
                    </Button>
                    <Button
                        size='large'
                        onClick={cancel}
                    >
                        取消
                    </Button>
                </Space>
            </Space>
        </Form>
    </>;
}
