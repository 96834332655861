import Fetch from "../../common/FetchIt";
import API from "../../common/api";

export type RoleType = {
    //添加患者
    ROLE_140001?: number | undefined,
    //导出待审核
    ROLE_140002?: number | undefined,
    //导入验真
    ROLE_140003?: number | undefined,
    //编辑
    ROLE_140004?: number | undefined,
    //详情
    ROLE_140005?: number | undefined,
    //预约
    ROLE_140006?: number | undefined,
    //改约
    ROLE_140007?: number | undefined,
    //客服添加患者
    ROLE_140008?: number | undefined,

    //签到
    ROLE_141001?: number | undefined,
    //改约
    ROLE_141002?: number | undefined,
    //提交申请
    ROLE_141003?: number | undefined,
    //撤销
    ROLE_141004?: number | undefined,
    //编辑
    ROLE_141005?: number | undefined,
    //上传义诊报告
    ROLE_141006?: number | undefined,
    //费用信息
    ROLE_141007?: number | undefined,
    //详情
    ROLE_141008?: number | undefined,
    //报销失败提交审核
    ROLE_141009?: number | undefined,

    //完成报销
    ROLE_146001?: number | undefined,
    //报销失败
    ROLE_146002?: number | undefined,
    //审核通过
    ROLE_146003?: number | undefined,
    //审核退回
    ROLE_146004?: number | undefined,
    //详情
    ROLE_146005?: number | undefined,
    //导出待报销名单
    ROLE_146006?: number | undefined,
    //查看导出记录
    ROLE_146007?: number | undefined,
    //下载导出记录
    ROLE_146008?: number | undefined,
}

export const dhStatusObj = {
    101: '待分配',
    102: '待审核',
    103: '审核退回',
    104: '待签到',
    105: '已签到',
    106: '不需要进行手术',
    107: '义诊已完成',
    108: '爽约',
    109: '待提交审核',
    110: '等待审核',
    111: '已撤消',
    112: '报销审核通过',
    113: '报销审核退回',
    115: '财务报销中',
    116: '报销失败',
    117: '等待医院申请报销',
    118: '报销审核通过',
    114: '完成',
}
const dhStatusOrder = [
    101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 116, 117, 118, 114
];

function buildDhStatusArr() {
    return dhStatusOrder.map(value => ({
        value,
        label: dhStatusObj[value],
    }));
}

export const dhStatusArray = buildDhStatusArr();

export const dhStatusColorObj = {
    101: 'black',
    102: 'black',
    103: 'black',
    104: 'black',
    105: 'black',
    106: 'black',
    107: 'black',
    109: 'black',
    108: 'black',
    110: 'black',
    111: 'black',
    112: 'black',
    113: 'red',
    114: 'black',
    115: 'black',
    116: 'red',
    117: 'black',
    118: 'black',
}

export const registerType = {
    1: '抖音小程序',
    2: '微信小程序',
    3: '电话报名',
    4: '抖音小程序预留',
    5: '微信小程序预留',
    6: '医院添加',
}

export const BookingTime = {
    1: '上午',
    2: '下午',
}

export const BookingStatus = {
    1: '已预约',
    2: '小程序签到',
    3: '医生代替签到',
    4: '改约',
    5: '爽约',
    6: '转院',
}

export const salesStatusObj = {
    1: '在线',
    2: '离线',
}

export const salesStatusColorObj = {
    1: 'green',
    2: 'red',
}

export const phoneTaskStatus = {
    1: '待生成批次',
    2: '待处理',
    3: '处理中',
    4: '处理完成',
}

export const dhPhoneTaskStatusArray = Object.entries(phoneTaskStatus).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
}));

export const phoneTaskType = {
    1: '预留手机号',
    2: '义诊回访',
    3: '爽约回访',
    4: '手术完成回访'
}

export const dhPhoneTaskTypeArray = Object.entries(phoneTaskType).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
}));

export const visitTasksType = {

    1: '义诊回访',
    2: '爽约回访',
    3: '手术完成回访'
}

export const warVeteranData = {

    1: '老兵本人',
    2: '家属',
}

type LabelType = {
    code: string | '',
    name: string | ''
}

export type DictObjectType = {

    dictInfo: {
        aier_relational: any[],
        aier_payment: LabelType[],
        war_veteran: LabelType[],
        veteran_relations: any[],
        dhOperationArray: any[],
        noSurgeryReasonArray: any[]
        serviceIdentity: LabelType[]
    }
}

export type DictType = {

    aier_relational: any[],
    aier_payment: LabelType[],
    war_veteran: LabelType[],
    veteran_relations: any[],
    dhOperationArray: any[],
    noSurgeryReasonArray: any[],
    serviceIdentity: LabelType[]
}

function createDictType(): DictType {

    return {
        aier_relational: [],
        aier_payment: [],
        war_veteran: [],
        veteran_relations: [],
        dhOperationArray: [],
        noSurgeryReasonArray: [],
        serviceIdentity: []
    }
}

export const defDictData = createDictType();


export const getDictData = async (): Promise<DictType> => {

    let types = 'aier_payment,aier_relational,war_veteran,veteran_relations,dh_operation,no_surgery_reason,service_identity';
    const data: {} = await Fetch.get(`${API.dict.find_by_types}?types=${types}`);
    //aier_relational
    const aier_relational = data['aier_relational'];
    let result: { value: string, label: number }[] = []
    for (let i = 0; i < aier_relational.length; i++) {
        const item = aier_relational[i];
        result.push({value: item.code, label: item.name})
    }
    //veteran_relations
    const veteran_relations = data['veteran_relations'];
    let veteranRelationsResult: { value: string, label: number }[] = []
    for (let i = 0; i < veteran_relations.length; i++) {
        const item = veteran_relations[i];
        veteranRelationsResult.push({value: item.code, label: item.name})
    }
    let dh_operation: any[] = [];
    let no_surgery_reason: any[] = [];
    data['dh_operation'].map((item: any) => {
        dh_operation.push({
            value: item.code,
            label: item.name
        })
    })
    data['no_surgery_reason'].map((item: any) => {
        no_surgery_reason.push({
            value: item.code,
            label: item.name
        })
    })
    return {
        aier_payment: data['aier_payment'],
        aier_relational: result,
        war_veteran: data['war_veteran'],
        veteran_relations: veteranRelationsResult,
        dhOperationArray: dh_operation,
        noSurgeryReasonArray: no_surgery_reason,
        serviceIdentity: data['service_identity']
    };
}


export const DhSelectStatus = (arr: any[], that: any) => {

    let temp: any[] = [];
    dhStatusArray.forEach(item => {
        if (arr.indexOf(item.value) >= 0) {
            temp.push(item);
        }
    })
    that.setState({
        statusListArr: temp
    })
};


export const showFormArray = ['1', '2', '3'];


export const unitObj = {

    种植牙: "颗",
    全瓷冠修复: "颗",
    嵌体修复: "颗",
    活动义齿: "例",
    根管治疗: "颗"
}

export const fieldName = {

    种植牙: {project: "typeDetailId1", num: "teethNum1"},
    全瓷冠修复: {project: "typeDetailId2", num: "teethNum2"},
    嵌体修复: {project: "typeDetailId3", num: "teethNum3"},
    活动义齿: {project: "typeDetailId4", num: "teethNum4"},
    根管治疗: {project: "typeDetailId5", num: "teethNum5"}
}