import React, {useEffect, useRef, useState} from 'react';
import Api from "../../common/api";
import API from "../../common/api";
import {Button, Card, Descriptions, Flex, Image as AntdImage, message, Modal, Space, Spin} from "antd";
import Fetch from "../../common/FetchIt";
import {CheckCircleOutlined} from '@ant-design/icons';
import DhServiceUserReimbursedBatchFailedModal from "./DhServiceUserReimbursedBatchFailed";
import createModal from "../../common/createModal";

const ImportDhServiceUserReimbursedBatchFailed = createModal(DhServiceUserReimbursedBatchFailedModal);
type ImageCropperType = {
    fullImage: string | null,
    serviceUserData: ServiceUserType[]
    releaseTime: string | null
}

type ServiceUserType = {
    name: string | undefined,
    price: string | undefined,
    backCard: string | undefined,
    serviceId: number | undefined,
    msg: string | undefined,
    failedMsg: string | undefined,
    click: boolean
}
type VoucherShowType = ServiceUserType & {
    img: any,
    confirmSer: boolean
}
const {confirm} = Modal;


const ImageCropperVoucher = (prop: ImageCropperType) => {

    const [croppedImages, setCroppedImages] = useState<VoucherShowType[]>([]);
    const [importLoading, setImportLoading] = useState<boolean | undefined>(false);
    const [tip, setTip] = useState<string | undefined>('');
    const [failedObj, setFailedObj] = useState<VoucherShowType | undefined>(undefined);
    let importDhServiceUserReimbursedBatchFailed: any = null;

    const canvasRef = useRef(null);
    // formRef = React.createRef<FormInstance>();

    useEffect(() => {

        if (prop.serviceUserData) {
            handleCropImages()
        } else {
            setCroppedImages([])
        }
    }, [prop.serviceUserData])

    const handleCropImages = () => {
        if (prop.fullImage == null) {
            return;
        }
        showSpin("图片截取中...")
        setCroppedImages([]);
        const fullImg = new Image();
        fullImg.src = Api.domain + prop.fullImage;
        fullImg.crossOrigin = "anonymous";
        let useHeight = 0;
        fullImg.onload = () => {
            const croppedImagesArray: VoucherShowType[] = [];
            const height = 142;
            console.log(prop.serviceUserData)
            prop.serviceUserData.forEach((item: ServiceUserType, index: number) => {
                const canvas = document.createElement('canvas');
                canvas.width = fullImg.width;
                canvas.height = fullImg.height;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(fullImg, 0, 0);
                    const croppedImageData = ctx.getImageData(0, useHeight, fullImg.width, height);
                    const croppedCanvas = document.createElement('canvas');
                    const croppedCtx = croppedCanvas.getContext('2d');
                    croppedCanvas.width = fullImg.width;
                    croppedCanvas.height = height;
                    if (croppedCtx) {
                        croppedCtx.putImageData(croppedImageData, 0, 0);
                    }
                    const img = croppedCanvas.toDataURL();
                    let voucherShow: VoucherShowType = {...item, img: item.serviceId ? img : '', confirmSer: false, click: false};
                    croppedImagesArray.push(voucherShow);
                    useHeight = useHeight + height;
                }
            });
            hideSpin()
            setCroppedImages(croppedImagesArray);
        };
    };

    const submit = (value: any) => {

        confirm({
            title: `是否确认提交？`,
            onOk() {
                showSpin("数据提交中...")
                const params = {
                    serviceId: value.serviceId,
                    releaseTime: prop.releaseTime,
                    file: value.img,
                    fileName: value.name
                }
                Fetch.postJSON(API.dentistry.service_user_status.finance_batch_confirm, params).then((data) => {
                    croppedImages.forEach((item: VoucherShowType) => {
                        if (item.serviceId === value.serviceId) {
                            item.confirmSer = true;
                        }
                    })
                    setCroppedImages(croppedImages);
                    hideSpin()
                    message.success("操作成功！")
                }).catch(e => {
                    hideSpin()
                });
            }
        });
    }

    const showSpin = (tip: string) => {
        setImportLoading(true)
        setTip(tip);
    }

    const hideSpin = () => {
        setImportLoading(false)
        setTip('');
    }

    const cardClick = (obj: any, objIndex: number) => {

        if (croppedImages.some(item => item.serviceId === obj.serviceId && item.confirmSer)) {
            message.error("已经操作完成。");
            return;
        }
        if (obj.serviceId === null) {
            message.error(obj.msg);
            return;
        }
        setCroppedImages(prevImages =>
            prevImages.map((item, index) =>
                (obj.serviceId !== null && item.serviceId === obj.serviceId) ||
                (obj.serviceId === null && index == objIndex) ? {
                    ...item,
                    click: !item.click
                } : item
            )
        );
    }

    const batchSubmit = () => {

        let croppedClick: VoucherShowType[] = [];
        croppedImages.forEach((item) => {
            if (item.click) {
                croppedClick.push(item);
            }
        })
        if (croppedClick.length === 0) {
            message.error("请选择要完成报销的患者")
            return;
        }
        let nameStr = "";
        let serviceIds: number[] = [];
        croppedClick.forEach((item) => {
            if (item.serviceId) {
                serviceIds.push(item.serviceId)
                nameStr = nameStr + item.name + ',';
            }
        })

        confirm({
            title: `${nameStr}${serviceIds.length}人是否确认完成？`,
            onOk() {
                showSpin("数据提交中...")
                let params: any = [];
                croppedClick.forEach((item) => {
                    params.push({
                        serviceId: item.serviceId,
                        releaseTime: prop.releaseTime,
                        file: item.img,
                        fileName: item.name
                    })
                })
                const paramObj =
                    Fetch.postJSON(API.dentistry.service_user_status.finance_batch_multiple_confirm, params)
                        .then((data) => {
                            setCroppedImages(prevImages =>
                                prevImages.map(item =>
                                    item.click ? {...item, confirmSer: true, click: false} : item
                                )
                            );
                            hideSpin()
                            message.success("操作成功！")
                        }).catch(e => {
                        hideSpin()
                    });
            }
        })
    }

    const doSetFailedObj = (item: VoucherShowType) => {

        setFailedObj(item)
        importDhServiceUserReimbursedBatchFailed.show()
    }

    const failedBack = (back: any, serviceId: number) => {

        if (back) {
            setCroppedImages(prevImages =>
                prevImages.map(item =>
                    item.serviceId === serviceId ? {...item, confirmSer: true, click: false} : item
                )
            );
        }
    }

    // @ts-ignore
    return (
        <div>
            <Spin tip={tip} spinning={importLoading}>
                <Flex gap="middle" key={"Flex"}>
                    <Space direction="vertical" style={{width: "100%"}}>
                    {croppedImages.map((item, index) => (
                        <Card key={index} onClick={() => cardClick(item, index)}
                              style={{backgroundColor: (item.click ? ("#e6f4ff") : (""))}}
                              title={"序号：" + (index + 1)}>
                                <Descriptions>
                                    <Descriptions.Item label="收款人姓名">
                                        {item.name}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="银行卡号">
                                        {item.backCard}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="报销金额">
                                        {item.price}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions>
                                    <Descriptions.Item label="警告">
                                        {item.msg && (
                                            <span style={{
                                                color: 'red', border: '1px dashed',
                                                padding: '0 10px', borderRadius: '5px'
                                            }}>
                                                {item.msg}
                                            </span>
                                        )}
                                    </Descriptions.Item>
                                </Descriptions>
                            <Flex gap="middle" justify={"space-between"} align={"flex-end"}>
                                    <AntdImage src={item.img}/>
                                    {item.img && (
                                        <>
                                            {
                                                item.confirmSer ? (
                                                    <CheckCircleOutlined
                                                        style={{fontSize: 'xx-large', color: '#1677ff'}}/>
                                                ) : (
                                                    <>
                                                        <Flex gap="middle">
                                                            <Button type={"primary"}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        submit(item);
                                                                    }}>完成报销</Button>
                                                            <Button danger={true}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        doSetFailedObj(item);
                                                                    }}>报销失败</Button>
                                                        </Flex>
                                                    </>
                                                )
                                            }
                                        </>
                                    )}
                                </Flex>
                            </Card>
                    ))}
                        <Card>
                            <Button type={"primary"} onClick={batchSubmit}>批量完成</Button>
                        </Card>
                </Space>
                </Flex>
                <canvas ref={canvasRef} style={{display: 'none'}}/>
            </Spin>
            <ImportDhServiceUserReimbursedBatchFailed
                width={500}
                title={'报销失败'}
                serviceId={failedObj?.serviceId}
                msg={failedObj?.failedMsg}
                // onCancel={this.closeModal}
                failedBack={failedBack}
                ref={(c) => (importDhServiceUserReimbursedBatchFailed = c)}
            />
        </div>
    );
};

export default ImageCropperVoucher;
